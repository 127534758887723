import React from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import APP_IMAGES from '../../constants/appImages';
import TpButton from '../ui/Button';
import HomeHeroAnimation from '../../lotties/home/hero-animation.json';
const LazyAnimation = dynamic(() => import('../common/Animation'), { ssr: false })

const Hero = () => {
  const desktop = (
    <section className="hidden lg:flex flex-row justify-center items-center container mx-auto mt-16 md:mt-24 px-10">
      <div className="w-full xl:max-w-3xl">
        <h6 className="mb-4 text-primary font-semibold text-2xl leading-7 uppercase">
          ALL-IN-ONE SOFTWARE PRODUCT DEVELOPMENT
        </h6>
        <div className="mb-6 relative">
          <h2 className="xl:max-w-2xl relative text-gray-darker font-bold md:text-4xl md:leading-[65px] after:absolute after:block after:bg-primary after:top-8 md:after:top-11 after:-z-10 after:right-20 lg:after:right-[180px] xl:after:right-[88px] after:w-20 md:after:w-[181px] after:h-[2px] md:after:h-[17px]">
            End-to-end Software Development Services for Evolutionary Solutions
          </h2>
          <div className="hidden xl:block absolute top-[59px] left-[-30px] z-10">
            <Image
              src={APP_IMAGES.circleImg}
              alt="circle"
              width={180}
              height={60}
            />
          </div>
        </div>
        <p className="text-gray-dark mb-16 text-2xl leading-10">
          We assemble and manage a top-tier software development team to realize
          your vision. Transform your business with <br />
          cutting-edge tools, resources, and a winning culture.
        </p>
        <TpButton
          className="flex mb-7 max-w-fit mt-4 md:w-auto h-12 md:h-16 font-semibold text-lg text-white transition hover:bg-white hover:text-primary"
          text="See How We Can Help"
          action="/contact"
          showArrow
        />
      </div>
      <div className="lg:w-[600px] lg:h-[530px]">
        <LazyAnimation file={HomeHeroAnimation} />
      </div>
    </section>
  );

  const mobile = (
    <section className="lg:hidden mt-8 sm:mt-14 px-6 mb-8">
      <div className="w-full min-h-64 sm:max-w-md sm:mx-auto mb-8">
        <Image
          src={APP_IMAGES.homeHeroImg}
          alt="Home Image"
          width={540}
          height={300}
          priority
        />
      </div>
      <h6 className=" mb-2 sm:mb-1 text-primary font-semibold text-lg leading-5 md:text-2xl md:leading-7 uppercase">
        ALL-IN-ONE SOFTWARE PRODUCT DEVELOPMENT
      </h6>
      <h2 className="mb-2 sm:mb-4 text-gray-darker font-medium text-lg leading-5 sm:font-bold sm:text-[22px] sm:leading-[33px]">
        End-to-end Software Development Services for Evolutionary Solutions
      </h2>
      <p className="w-full text-gray-dark mb-4 sm:mb-8 text-sm md:text-lg md:leading-6">
        We assemble and manage a top-tier software development team to realize
        your vision. Transform your business with cutting-edge tools, resources,
        and a winning culture.
      </p>
      <TpButton
        className="w-full sm:w-[334px] mt-4 px-[95px] py-[14px] rounded-lg text-white"
        text="See How We Can Help"
        action="/contact"
      />
    </section>
  );

  return (
    <>
      {desktop}
      {mobile}
    </>
  );
};

export default Hero;
